import React from "react"

import { graphql } from 'gatsby';

import CaseStudies from '../Elements/CaseStudies';
import CustomerExperience from '../Elements/CustomerExperience';
import SectionGradient from '../Elements/SectionGradient';
import Testimonials from '../Elements/Testimonials';

const Conclusion = ({
  slice: {
    primary: {
      case_study,
      case_study_title,
    },
  },
}) => (
  <>
    <div className="w-full grid md:grid-cols-2 items-center content-center gap-16 relative max-w-7xl mx-auto mt-12 md:mt-24 lg:mt-48">
      <div
        className="bg8 hidden lg:block left-24 -top-56 absolute"
        style={{ transform: 'translateX(-50%) scale(-0.75, 0.75) rotate(30deg)' }}
      />
      <div className="flex flex-row item-center justify-center relative z-10">
        <span className="stripes6 absolute" />
        <h2 className="xl:text-6xl text-5xl text-blue font-medium max-w-md xl:max-w-lg text-center">
          {case_study_title}
        </h2>
        <span className="stripes7 absolute" />
      </div>
      <div className="flex z-20">
        <CaseStudies
          cols="1"
          studies={[case_study.document.data]}
        />
      </div>
    </div>
    <div className="w-full relative h-32">
      <SectionGradient color="white" position="bottom" />
    </div>
    <Testimonials theme="light" />
    <div className="bg-gradient xl:py-32 md:py-24 py-16 text-white">
      <CustomerExperience items="clients" />
    </div>
  </>
);

export default Conclusion;

export const query = graphql`
  fragment IndustryConclusionSlice on PrismicIndustryDataBodyConclusion {
    id
    slice_type
    primary {
      case_study_title
      case_study {
        document {
          ... on PrismicCasestudies {
            id
            data {
              href: casestudy_link
              title {
                text
                html
              }
              description {
                text
                html
              }
              image {
                dimensions {
                  width
                  height
                }
                fluid(maxHeight: 376, maxWidth: 530) {
                  srcWebp
                  srcSetWebp
                }
              }
              mobile_image {
                dimensions {
                  width
                  height
                }
                fluid(maxWidth: 230, maxHeight: 260) {
                  srcWebp
                  srcSetWebp
                }
              }
            }
          }
        }
      }
    }
  }
`
