import React from "react"

import { graphql } from 'gatsby';

import StripeSection from '../Elements/StripeSection';
import LinearContentBlock from '../Elements/LinearContentBlock';

const Content = ({ slice: { primary, items } }) => (
  <div className="relative w-full">
    <div
      className="bg8 hidden lg:block -right-24 top-16 absolute"
    />
    <div
      className="bg8 hidden lg:block left-6 top-[540px] absolute"
      style={{ transform: 'translateX(-50%) scale(-0.75, 0.75) rotate(30deg)' }}
    />
    <StripeSection className="pt-16 relative z-10" {...primary}>
      <LinearContentBlock
        entries={items.map(({ description: { richText }, ...rest }) => ({
          content: richText, ...rest
        }))}
      />
    </StripeSection>
  </div>
);

export default Content;

export const query = graphql`
  fragment IndustryContentSlice on PrismicIndustryDataBodyContent {
    id
    slice_type
    primary {
      title
    }
    items {
      title
      description {
        richText
      }
      image {
        dimensions {
          width
          height
        }
        fluid {
          srcSetWebp
          srcWebp
        }
      }
      button_text
      button_link
    }
  }
`
