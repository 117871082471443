import React from "react"
import { graphql } from "gatsby"
import { PrismicRichText } from '@prismicio/react';

import boxImage from '../../assets/images/shipment.png';
import birdSticker from '../../assets/images/bird-sticker.png';

import GetStartedButton from '../Buttons/ButtonGetStarted';
import WatchDemoButton from '../Buttons/ButtonWatchDemo';

import OutOfTheBoxContent from '../Elements/OutOfTheBoxContent';
import TrustedBrands from '../Elements/TrustedBrands';

const HeroSection = ({
  slice: {
    primary: {
      title,
      description: { richText: description },
      background_image,
    },
  },
}) => (
  <>
    <div className="w-full relative">
      <div className="flex flex-col md:items-center gap-2 relative z-30 max-w-lg lg:max-w-xl xl:max-w-3xl 2xl:max-w-4xl mx-auto md:text-center py-32 md:pt-[14vw] md:pb-[22vw] px-4">
        <h2 className="text-blue font-medium 2xl:text-7xl xl:text-6xl lg:text-5xl sm:text-4xl text-3xl tracking-tighter">
          {title}
        </h2>
        <div></div>
        <PrismicRichText
          field={description}
          components={{
            paragraph: ({ children }) => (
              <p className="text-lg text-blue pb-3 max-w-md">
                {children}
              </p>
            ),
          }}
        />
        <div className="flex flex-row gap-4 flex-wrap">
          <GetStartedButton className="m-0" />
          <WatchDemoButton className="m-0" />
        </div>
      </div>
      {background_image === 'squares' ? (
        <>
          <div
            className="absolute left-0 top-1/2 aspect-square w-[150vw] hidden md:block"
            style={{
              background: 'linear-gradient(270deg, #FB2661 -1.94%, rgba(251, 38, 97, 0.00) 89.44%)',
              outline: '10px solid #FB2661',
              outlineOffset: '-1px',
              transform: 'translate(-105%, -50%) rotate(45deg)',
            }}
          />
          <div
            className="absolute right-12 md:right-0 top-[75%] md:top-1/2 aspect-square w-[150vw]"
            style={{
              background: 'linear-gradient(270deg, #FB2661 -1.94%, rgba(251, 38, 97, 0.00) 89.44%)',
              outline: '10px solid #FB2661',
              outlineOffset: '-1px',
              transform: 'translate(105%, -50%) scale(-100%, 100%) rotate(45deg)',
            }}
          />
        </>
      ) : background_image === 'pipes' ? (
        <>
          <div
             className="absolute bottom-0 left-0 w-full h-64 z-20"
             style={{ background: 'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%)' }}
          />
          <div className="absolute -left-40 -bottom-2">
            <div
              className="absolute left-0 bottom-0 aspect-square w-[30vw]"
              style={{ borderRadius: '20px', border: '0.75vw solid #FB2661' }}
            />
            <div
              className="absolute left-[29.3vw] -bottom-[28vw] aspect-square w-[40vw]"
              style={{ borderRadius: '20px', borderTopLeftRadius: 0, border: '0.75vw solid #FB2661' }}
            >
              <div className="absolute left-[8vw] -top-[3vw] w-[5vw] z-10">
                <img
                  aria-hidden="true"
                  className="w-full"
                  src={boxImage}
                  style={{ transform: 'rotate(75deg) scale(-100%, 100%)' }}
                />
              </div>
            </div>
          </div>
          <div className="absolute -right-[25vw] -bottom-2">
            <div
              className="absolute right-0 bottom-0 aspect-square w-[45vw]"
              style={{ borderRadius: '20px', border: '0.75vw solid #FB2661' }}
            >
              <div className="absolute -left-[2.8vw] top-[6vw] w-[5vw] z-10">
                <img
                  aria-hidden="true"
                  className="w-full"
                  src={boxImage}
                  style={{ transform: 'rotate(120deg) scale(-100%, 100%)' }}
                />
              </div>
            </div>
          </div>
        </>
      ) : background_image === 'lines' ? (
        <div className="absolute -right-[10vw] lg:-right-[15vw] -bottom-1 w-[40vw] flex flex-col-reverse gap-[1vw]">
          {[...Array(12).keys()].map((_, index) => (
            <>
              <div
                className="min-h-[1vw] w-full relative"
                style={{
                  background: 'linear-gradient(270deg, #FB2661 14.5%, rgba(251, 38, 97, 0.00) 85.59%)',
                  marginLeft: `${1.5 * index}vw`,
                }}
              />
              <div
                className="min-h-[1vw] w-full relative"
                style={{
                  background: 'linear-gradient(90deg, #FB2661 14.5%, rgba(251, 38, 97, 0.00) 85.51%)',
                  marginLeft: `${1.5 * index}vw`,
                }}
              >
                {index === 3 && (
                  <div className="absolute left-[3vw] top-1/2 aspect-square w-[4vw] -translate-y-[25%]">
                    <img
                      aria-hidden="true"
                      className="w-full"
                      src={birdSticker}
                    />
                  </div>
                )}
              </div>
            </>
          ))}
        </div>
      ) : null}
    </div>
    <TrustedBrands className="sm:pb-20 pt-40 md:pt-24 pb-48 md:pb-56 md:-mb-40 z-10" />
    <OutOfTheBoxContent />
  </>
);

export default HeroSection;

export const query = graphql`
  fragment IndustryHeroSlice on PrismicIndustryDataBodyHero {
    id
    slice_type
    primary {
      title
      description { richText }
      background_image
    }
  }
`
